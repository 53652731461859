import CompanyLogo from 'components/CompanyLogo';
import { Step, Steps } from 'components/Steps';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@laminar-product/client-commons-core/web';
import { useSignOut } from 'utils/profiles';
import { useLocation } from 'react-router';
import { upgradePlanParamId } from 'utils/constants';
import usePathWithParams from 'utils/usePathWithParams';
import { APP_PAGE_PATH } from 'utils/navigation';
import styles from './index.module.scss';

interface RegisterMenuProps {
  registerStep?: 0 | 1 | 2;
  isSignedIn?: boolean;
  variant: 'signIn' | 'signUp';
  backgroundColor?: string;
  onLogoClick?: () => void;
}

const AuthenticationMenu = ({
  registerStep,
  isSignedIn,
  backgroundColor,
  onLogoClick,
  variant,
}: RegisterMenuProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const signOut = useSignOut();
  const stepIconRender = ({ index }: { index: number }) => (
    <span>{index + 1}</span>
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const getPath = usePathWithParams();

  const onClick = () => {
    if (onLogoClick) {
      return onLogoClick();
    }
    push('/');
  };

  return (
    <nav className={styles.root} style={{ backgroundColor }}>
      <div onClick={onClick} className={styles.link}>
        <CompanyLogo />
      </div>
      <div className={styles.registerSteps}>
        {!!registerStep && !queryParams.get(upgradePlanParamId) && (
          <Steps current={registerStep} className={styles.steps}>
            <Step
              stepIcon={stepIconRender}
              title={t('registration.steps.signUp')}
            />
            <Step
              stepIcon={stepIconRender}
              title={t('registration.steps.choosePlan')}
            />
            <Step
              stepIcon={stepIconRender}
              title={t('registration.steps.paymentMethod')}
            />
          </Steps>
        )}
      </div>
      <div className={styles.actions}>
        {variant === 'signIn' ? (
          <Link className={styles.auth} to={getPath(APP_PAGE_PATH.REGISTER)}>
            {t('common.signUp')}
          </Link>
        ) : isSignedIn ? (
          <Button
            className={styles.button}
            variant="text"
            onClick={() => signOut()}
          >
            {t('common.signOut')}
          </Button>
        ) : (
          <Link className={styles.auth} to={getPath(APP_PAGE_PATH.LOGIN)}>
            {t('common.signIn')}
          </Link>
        )}
      </div>
    </nav>
  );
};

export default AuthenticationMenu;
