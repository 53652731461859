import { Button } from '@laminar-product/client-commons-core/web';
import { useSelector } from 'react-redux';
import { selectAppName } from 'store/app/selectors';
import Popup from 'components/Popup';
import { useMutation } from '@tanstack/react-query';
import { signOutAllDevices } from 'actions/user';
import { useSignOut } from 'utils/profiles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

interface SignOutOnAllDevicesModalProps {
  onClose: () => void;
}

const SignOutOnAllDevicesModal = ({
  onClose,
}: SignOutOnAllDevicesModalProps) => {
  const signOut = useSignOut();
  const appName = useSelector(selectAppName);
  const [error, setError] = useState<string>();
  const { t } = useTranslation();
  const message = `Are you sure you want to sign out of this ${appName} account on all devices?
The sign out may take a few minutes to fully take effect across all devices.`;

  const mutation = useMutation({
    mutationKey: ['signOutOnAllDevices'],
    mutationFn: signOutAllDevices,
    onSuccess() {
      onClose();
      signOut();
    },
    onError() {
      setError(t('errors.somethingWentWrong'));
    },
  });

  return (
    <Popup
      wrapperClassName={styles.popup}
      header="Sign out on all devices"
      message={message}
      extraContent={<p className={styles.errorMessage}>{error}</p>}
      actionButtons={
        <>
          <Button variant="secondary-settings" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="cta"
            onClick={() => mutation.mutate()}
            loading={mutation.isPending}
          >
            Sign out
          </Button>
        </>
      }
    ></Popup>
  );
};

export default SignOutOnAllDevicesModal;
