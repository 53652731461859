import { getCurrentSubscription } from 'actions/payments';
import { cancelable } from 'cancelable-promise';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUserSubscription } from 'store/user/actions';
import {
  selectFirebaseUser,
  selectUserSubscription,
} from 'store/user/selectors';

const useFetchUserSubscription = () => {
  const userSubscription = useSelector(selectUserSubscription);
  const [fetchingSubscriptionError, setFetchingSubscriptionError] =
    useState(false);
  const dispatch = useDispatch();
  const firebaseUser = useSelector(selectFirebaseUser);

  const refreshSubscription = () => {
    getCurrentSubscription()
      .then((subscription) => {
        dispatch(setCurrentUserSubscription(subscription));
      })
      .catch(() => setFetchingSubscriptionError(true));
  };

  useEffect(() => {
    //Note: Subscription can be null if does not exist
    if (
      userSubscription !== undefined ||
      !firebaseUser ||
      firebaseUser.isAnonymous
    )
      return;

    const fetch = cancelable(getCurrentSubscription());

    fetch
      .then((subscription) =>
        dispatch(setCurrentUserSubscription(subscription))
      )
      .catch(() => setFetchingSubscriptionError(true));

    return () => fetch.cancel();
  }, [dispatch, userSubscription, firebaseUser]);

  return {
    subscription: userSubscription,
    refreshSubscription,
    subscriptionError: fetchingSubscriptionError,
  };
};

export default useFetchUserSubscription;
