import { useTranslation } from 'react-i18next';
import { Button } from '@laminar-product/client-commons-core/web';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import styles from './index.module.scss';
import SignOutOnAllDevicesModal from './components/SignOutOnAllDevicesModa';

const Devices = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);

  return (
    <div className={styles.root}>
      {/* TODO: FETCH LIST OF REGISTERED DEVICES 
      <h2>{t('settings.registeredDevices')}</h2>

      <div className={styles.listContainer}>
        {mockedDevices.map((device) => (
          <DeviceElement onUnregister={console.log} {...device} />
        ))}
      </div> */}
      <div className={styles.buttonGroup}>
        {false && (
          <Button
            variant="secondary-settings"
            onClick={() => setIsSignOutModalOpen(true)}
          >
            {t('settings.devices.signOutOnAllDevices')}
          </Button>
        )}

        <Button
          variant="secondary-settings"
          onClick={() => push('/settings/devices/register')}
        >
          {t('settings.devices.registerNewDevice')}
        </Button>
      </div>

      {isSignOutModalOpen && (
        <SignOutOnAllDevicesModal
          onClose={() => setIsSignOutModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Devices;
