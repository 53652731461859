import { useEffect, useState } from 'react';
import api from 'api';
import { useSignOut } from './profiles';

const useAxiosResponseErrors = () => {
  const [unauthorized, setUnauthorized] = useState(false);
  const [responseError, setResponseError] = useState<string>();
  const signOut = useSignOut();

  useEffect(() => {
    api().interceptors.response.use(
      async (response) => {
        setUnauthorized(false);
        return response;
      },
      (e) => {
        setUnauthorized(e.response?.status === 401);
        setResponseError(e.response?.data?.code);
        return Promise.reject(e);
      }
    );
  }, []);

  useEffect(() => {
    if (responseError === 'auth/unauthorized') {
      signOut('/login');
    }
  }, [responseError, signOut]);

  const resetErrors = () => setUnauthorized(false);

  return { unauthorized, resetErrors };
};

export default useAxiosResponseErrors;
